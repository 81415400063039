<template>
  <div class="friendsCircle">
    <div class="friendsCircle-image">
        <el-image class="friendsCircle-image-logo" :src="require(`../../assets/image/logo.png`)"></el-image>
        <el-image class="friendsCircle-image-title" :src="require(`../../assets/image/friendsCircle.png`)"></el-image>
    </div>
    <div class="content">
        <div class="content-left">
            <div class="content-text">
                <div class="content-text-1">
                    <div>
                        <span class="title">总体人设</span>
                        <el-tooltip class="item" effect="dark" content="风格+产品+地域+性别+职业+目标用户" placement="top">
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </div>
                    <div>
                      <textarea class="global-textarea" v-model="global"></textarea>
                      <div class="remark">灵感：<span class="remark-content">温柔亲和力强的女老师，在杭州大嘴鸟编程教乐高课。</span></div>
                    </div>
                </div>
                <div class="content-text-2">
                    <div>
                        <span class="title">目标客户</span>
                        <el-tooltip class="item" effect="dark" content="宝妈、家庭教育工作者、自媒体人" placement="top">
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </div>
                    <div>
                      <textarea class="global-textarea" v-model="target"></textarea>
                    </div>
                </div>
                <div class="content-text-3">
                    <span class="title">朋友圈类型</span>
                    <el-tooltip class="item" effect="dark" content="每天5条朋友圈：2条生活圈+3条专业圈" placement="top">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                    <div class="div-type">
                      <span class="global-textarea">{{ friendsType }}</span>
                      <div class="dropdown-div">
                        <el-dropdown @command="handleCommand">
                          <span class="el-dropdown-link">
                            类型选择<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="生活类">生活类</el-dropdown-item>
                            <el-dropdown-item command="专业类">专业类</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                </div>
                <div class="content-text-4">
                    <div>
                        <span class="title">朋友圈内容要求</span>
                        <el-tooltip class="item" effect="dark" content="大嘴鸟编程3周年庆，需要生成一个周年庆活动宣传的朋友圈，内容包含周年庆时间及周年庆优惠信息。" placement="top-end">
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </div>
                    <div>
                      <textarea class="global-textarea" v-model="friends"></textarea>
                    </div>
                </div>
                <div class="content-text-5">
                    <div class="div-helper">
                      <span class="title">朋友圈格式要求</span>
                      <el-popover placement="right" width="400" trigger="click">
                        
                        <div style="
                          height: 240px;
                          display: flex;
                          flex-direction: column;
                          padding: 6px;
                        ">
                          <div style="font-size: 20px;font-weight: bold;margin-bottom: 22px;color: #333333;">小助手</div>
                          <div style="color: #6c61ed;margin-bottom: 8px;font-weight: bold;">风格要求</div>
                          <div style="display: flex;justify-content: space-around;">
                            <span @click="friendsFormatAdd(1)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[1] }}</span>
                            <span @click="friendsFormatAdd(2)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[2] }}</span>
                            <span @click="friendsFormatAdd(3)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[3] }}</span>
                            <span @click="friendsFormatAdd(4)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[4] }}</span>
                          </div>
                          <div style="margin-top: 8px;margin-bottom: 16px;">
                            <span @click="friendsFormatAdd(10)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[10] }}</span>
                          </div>
                          <div style="color: #6c61ed;margin-bottom: 8px;font-weight: bold;">形式要求</div>
                          <div style="display: flex;justify-content: space-around;">
                            <span @click="friendsFormatAdd(5)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[5] }}</span>
                            <span @click="friendsFormatAdd(6)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[6] }}</span>
                            <span @click="friendsFormatAdd(7)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[7] }}</span>
                            <span @click="friendsFormatAdd(8)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[8] }}</span>
                          </div>
                          <div style="margin-top: 8px;">
                            <span @click="friendsFormatAdd(9)" style="background-color: #eeeeee; padding: 2px 10px;font-weight: bold;border-radius: 8px;color: #333;cursor: pointer;">{{ friendsFormatList[9] }}</span>
                          </div>
                        </div>

                        <div class="assistant" slot="reference">
                          小助手
                          <i class="el-icon-s-opportunity"></i>
                        </div>
                      </el-popover>

                    </div>
                    <div>
                      <textarea class="global-textarea" v-model="friendsFormat"></textarea>
                    </div>
                    <el-button class="produce-button" @click="sendMessage">立即生成</el-button>
                </div>
            </div>
        </div>
        <div class="content-right">
            <div class="content-text-1" style="white-space: pre-wrap;">{{ answer }}</div>
            <div class="content-text-2" style="white-space: pre-wrap;">{{ historyOne }}</div>
            <div class="content-text-3" style="white-space: pre-wrap;">{{ historyTwo }}</div>
            <div class="content-text-4" style="white-space: pre-wrap;">{{ historyThree }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import { getFriends, getFriendsHistory } from '../../request/api'

export default {
  data() {
    return {
      global: '',  // 总体人设
      target: '',  // 目标客户
      friends: '',  // 朋友圈内容要求
      friendsType: '',  // 朋友圈类型
      friendsFormat: '',  // 朋友圈格式要求
      friendsFormatList: {
        1: '热情有趣',
        2: '口语化',
        3: '专业化',
        4: '制造惊喜感和紧迫感',
        5: '50字以内',
        6: '短句为主',
        7: '带2个表情包',
        8: '一句换一行排列',
        9: '有一个问句',
        10: '目标客户有代入感'
      },
      textAddTrue: [],
      answer: '',
      historyOne: '暂无历史记录',
      historyTwo: '暂无历史记录',
      historyThree: '暂无历史记录',
      isLoading: false
    }
  },
  methods: {
    handleCommand (command) {
      this.friendsType = command
    },
    friendsFormatAdd(text) {
      if (this.textAddTrue.find(element => element === text)) {
        this.$message.error('已经添加过了！');
      } else {
        this.textAddTrue = [...this.textAddTrue, text]
        if (this.friendsFormat === '') {
          this.friendsFormat = this.friendsFormatList[text]
        } else {
          this.friendsFormat = this.friendsFormat + ',' + this.friendsFormatList[text]
        }
      }
    },
    // 生成朋友圈文案
    async sendMessage () {
      if (
        this.global.trim() === '' ||
        this.target.trim() === '' ||
        this.friends.trim() === '' ||
        this.friendsType.trim() === '' ||
        this.friendsFormat.trim() === ''
      ) {
        this.$message.warning('请完善字段信息')
        return
      }
      if (this.isLoading) return

      this.isLoading = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      });
      try {
        let getData = {
          message: '需要你写1条朋友圈的文案。我是一个' + this.global + ';我的目标客户：' + this.target + ';朋友圈类型：' + this.friendsType + ';请您根据我下面给你的内容，帮我写一条朋友圈文案：' + this.friends + ';朋友圈格式要求：' + this.friendsFormat
        }
        const res = await getFriends(getData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.answer = res.data.data.answer

        let parameters = res.data.data.parameters
        if (Array.isArray(parameters) && parameters.length !== 0) {
          let historyId = 0
          for (let element of parameters) {
            if (element.role === 'user') {
              continue
            }
            if (historyId === 0) {
              this.historyOne = element.content
            } else if (historyId === 1) {
              this.historyTwo = element.content
            } else {
              this.historyThree = element.content
            }
            historyId++
          }
        }

        this.isLoading = false
        loading.close()
      } finally {
        this.isLoading = false
        loading.close()
      }
    },
    async init () {
      const res = await getFriendsHistory()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      let friendsHistory = res.data.data
      if (Array.isArray(friendsHistory) && friendsHistory.length !== 0) {
        let historyId = 0
        for (let element of friendsHistory) {
          if (historyId === 0) {
            this.historyOne = element.assistant_content
          } else if (historyId === 1) {
            this.historyTwo = element.assistant_content
          } else {
            this.historyThree = element.assistant_content
          }
          historyId++
        }
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.friendsCircle {
  background-image: linear-gradient(to bottom left, #8b77ea, #579ce9);
  // 全屏显示背景
  min-height: 100vh;
  // 背景固定，内容可滚动
  background-attachment: fixed;
  // 背景图平铺
  background-repeat: no-repeat;
  // 背景图的位置
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 36px;
  .friendsCircle-image {
    text-align: center;
    position: relative;
    @media screen and (min-width: 1014px) {
      // 当屏幕尺寸大于768px时应用
      .friendsCircle-image-title {
        width: 788px;
      }
      .friendsCircle-image-logo {
        width: 150px;
      }
    }
    @media screen and (max-width: 1014px) {
      // 当屏幕尺寸小于或等于768px时应用
      .friendsCircle-image-title {
        width: 240px;
      }
      .friendsCircle-image-logo {
        width: 40px;
      }
    }
    .friendsCircle-image-logo {
      position: absolute;
      left: 0;
    }
  }
  @media screen and (min-width: 1014px) {
    // 当屏幕尺寸大于768px时应用
    .content {
      display: flex;
    }
  }
  .content {
    flex: 1;
    .content-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 835px;
      .content-text {
        background-color: #fff;
        height: 786px;
        width: 98%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        box-sizing: border-box;
        .content-text-1 {
          height: 170px;
          padding: 16px 30px;
          box-sizing: border-box;
          border-bottom: 2px solid rgb(171, 171, 171);
          .title {
            color: #7272ee;
            font-weight: bold;
            margin-right: 4px;
          }
          .global-textarea {
            background-color: #f3f8fe;
            resize: none;
            width: 100%;
            height: 80px;
            margin-top: 6px;
            border-radius: 6px;
          }
          .remark {
            color: #9e9495;
            font-weight: bold;
            font-size: 14px;
            .remark-content {
              color: #5785dc;
            }
          }
        }
        .content-text-2 {
          border-bottom: 2px solid rgb(171, 171, 171);
          height: 126px;
          padding: 16px 30px;
          box-sizing: border-box;
          .global-textarea {
            background-color: #f3f8fe;
            resize: none;
            width: 100%;
            height: 60px;
            margin-top: 6px;
            border-radius: 6px;
          }
          .title {
            color: #7272ee;
            font-weight: bold;
            margin-right: 4px;
          }
        }
        .content-text-3 {
          border-bottom: 2px solid rgb(171, 171, 171);
          height: 96px;
          padding: 16px 30px;
          box-sizing: border-box;
          .title {
            color: #7272ee;
            font-weight: bold;
            margin-right: 4px;
          }
          .div-type {
            display: flex;
            margin-top: 6px;
            width: 100%;

            @media screen and (min-width: 1014px) {
              // 当屏幕尺寸大于768px时应用
              .global-textarea {
                width: 80%;
              }
              .dropdown-div {
                width: 20%;
              }
            }
            @media screen and (max-width: 1014px) {
              // 当屏幕尺寸小于或等于768px时应用
              .global-textarea {
                width: 66%;
              }
              .dropdown-div {
                width: 34%;
              }
            }

            .global-textarea {
              background-color: #f3f8fe;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              height: 34px;
              line-height: 34px;
              font-size: 12px;
              padding-left: 2px;
            }
            .dropdown-div {
              background-color: #f3f8fe;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              height: 34px;
              .el-dropdown-link {
                cursor: pointer;
                color: #000000;
                font-weight: bold;
                line-height: 34px;
              }
            }
          }
        }
        .content-text-4 {
          border-bottom: 2px solid rgb(171, 171, 171);
          height: 170px;
          padding: 16px 30px;
          box-sizing: border-box;
          .global-textarea {
            background-color: #f3f8fe;
            resize: none;
            width: 100%;
            height: 102px;
            margin-top: 6px;
            border-radius: 6px;
          }
          .title {
            color: #7272ee;
            font-weight: bold;
            margin-right: 4px;
          }
        }
        .content-text-5 {
          height: 184px;
          padding: 16px 30px;
          box-sizing: border-box;
          .global-textarea {
            background-color: #f3f8fe;
            resize: none;
            width: 100%;
            height: 102px;
            margin-top: 6px;
            border-radius: 6px;
          }
          .div-helper {
            display: flex;
            justify-content: space-between;
            .assistant {
              border: 1px solid #4591fb;
              border-radius: 15px;
              padding: 2px 10px;
              font-size: 12px;
              font-weight: lighter;
              cursor: pointer;
            }
            .assistant:hover {
              box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
            }
            .assistant:active {
              background-color: #0056b3;
              color: #fff;
            }
            .title {
              color: #7272ee;
              font-weight: bold;
            }
          }
          .produce-button {
            width: 100%;
            color: #fff;
            font-weight: bold;
            background-image: linear-gradient(to right, #7763ea, #569be8);
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
            border: none;
            margin-top: 6px;
          }
          .produce-button:hover {
            box-shadow: 0 6px 14px 0 rgba(0, 0, 0, .2);
          }
          .produce-button:active {
            box-shadow: inset 0 3px 4px rgba(0, 0, 0, .2);
          }
        }
      }
    }
    .content-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 835px;
      .content-text-1 {
        width: 98%;
        height: 256px;
        background-color: #e5f1ff;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        white-space: pre-wrap
      }
      .content-text-2 {
        width: 98%;
        height: 120px;
        background-color: #e5f1ff;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        box-sizing: border-box;
        color: #919395;
        overflow-y: auto;
        white-space: pre-wrap
      }
      .content-text-3 {
        width: 98%;
        height: 200px;
        background-color: #e5f1ff;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        box-sizing: border-box;
        color: #919395;
        overflow-y: auto;
        white-space: pre-wrap
      }
      .content-text-4 {
        width: 98%;
        height: 180px;
        background-color: #e5f1ff;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        box-sizing: border-box;
        color: #919395;
        overflow-y: auto;
        white-space: pre-wrap
      }
    }
  }
}
</style>